import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'
// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/axios.js'

import _ from 'lodash'

Vue.config.devtools = true;

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
    router,
    store,
    data() {
        return {
            appAuthObject: {
                username: '',
                company_name: ''
            },
            accountManagerDetail: {
                name: '',
                email: '',
                mobile_phone: '',
                country_code: '',
                photo: ''
            },
            userLoginDetails: {
                contact_fname: '',
                contact_lname: '',
                contact_fullname: '',
                contact_mobile: '',
                // marketing_result_dashboard: '',
                company_name: '',
                company_email: '',
                brightlocal_report_id: '',
                active_brightlocal_report_id: '',
            },
            clientWebsiteObject: {
                status: '',
                statusVariant: '',
                url: '',
                websiteStatusChange: '',
                statusVariantStyle: ''
            },
            brightlocal_report_id: [],
            brightlocalData: {
                avgRating: true,
                totalReviews: true,
                starRatingCount: true,
                overviewTimeline: true,
                directoryList: true,
                reviews: {
                    data: true,
                },
                sourceDataInfo: true,
            }
        }
    },
    render: h => h(App),
    created() {
        this.getLoginDetails()
    },
    methods: {
        getUploadFilePath(path) {
            return `/public/app-assets/${path}`;
        },
        getLoginDetails() {
            this.$http.get("/get-login-details", {
                "Content-Type": "application/json",
            }).then(response => {
                response = response.data;
                if (response.status == 'success') {
                    this.$root.userLoginDetails.contact_fname = response.contact_fname;
                    this.$root.userLoginDetails.contact_lname = response.contact_lname;
                    this.$root.userLoginDetails.contact_fullname = response.contact_fullname;
                    this.$root.userLoginDetails.contact_mobile = response.contact_mobile;
                    this.$root.userLoginDetails.contact_email = response.contact_email;
                    // this.$root.userLoginDetails.marketing_result_dashboard = response.marketing_result_dashboard;
                    this.$root.userLoginDetails.company_name = response.company_name;
                    this.$root.userLoginDetails.company_email = response.company_email;
                    this.$root.userLoginDetails.brightlocal_report_id = response.brightlocal_report_id;
                    this.$root.userLoginDetails.active_brightlocal_report_id = response.brightlocal_report_id[0]?.id;
                }
            }).catch((error) => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        variant: 'danger',
                        text: error,
                    },
                });
            });
        },
    }
}).$mount('#app').$ability
